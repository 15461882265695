import MySelf from '../../images/profile/MySelf.png';
import TextAnimation from '../../components/Animations/TextAnimation';
import { GitHub, LinkedIn, Instagram } from '@mui/icons-material';
import 'bootstrap-icons/font/bootstrap-icons.css';
import FotoDetik from '../../images/profile/FotoDetik.jpeg'
import projects from '../../data/Projects';
import skills from '../../data/skills';

function Home() {
    return (
        <>
            <div className='w-full'>
                <section id="home" className="pt-32 lg:py-24 px-12 space-y-4">
                    <div className="container">
                        <div className="flex flex-wrap">
                            {/* Start Cta Profile */}
                            <div className="w-full self-center lg:w-2/4 px-2 py-5">
                                <h1 className="text-3xl font-semibold text-dark dark:text-light">
                                    <TextAnimation
                                        sequence={[
                                            "Hai Semuanya 👋",
                                            2000,
                                            "Saya Wayan Berdyanto",
                                            2000,
                                            "Saya sebagai Web Developer",
                                            2000
                                        ]} />
                                </h1>
                                <p className="mt-4 mb-10 leading-5 font-normal tracking-wider text-dark text-sm dark:text-light">Mahasiswa aktif Universitas Kristen Duta Wacana jurusan Sistem Informasi dengan pengalaman belajar mandiri di Bangkit Academy 2023 sebagai Mobile Development menggunakan Kotlin. Selain itu saya juga mempunyai kemampuan dalam mengembangkan website dan juga mampu berkolaborasi dengan tim.</p>
                                <a href="#about" className="border-[1px] rounded-md border-primary text-primary font-medium px-5 py-2 text-lg duration-500 hover:bg-primary hover:text-light">About Me</a>
                            </div>
                            {/* End Cta Profile */}
                            {/* Start Blob Maker Image */}
                            <div className="w-full self-center lg:w-2/6 px-2 py-5">
                                <div className="relative lg:mt-8 lg:-right-28">
                                    <img src={MySelf} alt="Gambar Error" height={250} width={300} className="max-w-full mx-auto bg-cover" />
                                    <span className="absolute -bottom-0 -z-10 left-1/2 -translate-x-1/2 md:scale-125">
                                        <svg height={350} width={350} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#03AED2" d="M63,-51.1C77.6,-32.1,82.4,-6.1,76.8,17.1C71.2,40.3,55.1,60.8,33.8,71.6C12.5,82.4,-14.2,83.6,-36.7,73.7C-59.2,63.8,-77.5,42.8,-83.6,18.4C-89.6,-5.9,-83.4,-33.8,-67.5,-53.1C-51.5,-72.5,-25.7,-83.4,-0.7,-82.8C24.3,-82.2,48.5,-70.1,63,-51.1Z" transform="translate(100 100)" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            {/* Start Blob Maker Image */}
                        </div>
                    </div>
                </section>
                <section id="about" className="px-12 pt-32 space-y-4">
                    <div className="container">
                        <div className="flex flex-wrap">
                            <div className="w-full px-2">
                                <h1 className="text-center tracking-widest text-dark text-2xl font-light mb-8 dark:text-light">ABOUT ME</h1>
                                <div className="flex justify-center" >
                                    <img src={FotoDetik} alt="" className='d-block rounded-full h-32' />
                                </div>
                                <h4 className='text-center my-3 text-xl text-gray-500 dark:text-gray-400'>Wayan Berdyanto</h4>
                                <div className='lg:px-32 text-sm'>
                                    <p className='text-center leading-6 tracking-wide text-dark dark:text-light'>Mulai terjun ke Dunia Programming sejak Tahun 2021 di UKDW sebagai mahasiswa Sistem Informasi, saya mengasah keterampilan saya sebagai Front End Developer kemudian saya juga tertarik pembelajaran backend. Setelah itu saya berkomitmen untuk menjadi Fullstactk Developer.</p>
                                </div>
                                <div className='flex gap-4 justify-center my-4'>
                                    <a href="https://github.com/WayanBerdyanto" className='hover:text-red-600 duration-500'>
                                        <GitHub />
                                    </a>
                                    <a href="https://www.linkedin.com/in/wayanberdyanto/" className='hover:text-blue-600 duration-500'>
                                        <LinkedIn />
                                    </a>
                                    <a href="https://www.instagram.com/_alphabetqwerty/" className='hover:text-pink-600 duration-500'>
                                        <Instagram />
                                    </a>
                                </div>
                                <div className='flex justify-center'>
                                    <a className='text-center border-[1px] rounded-md border-primary text-primary font-normal px-3 py-1 text-lg duration-500 hover:bg-primary hover:text-light' href="https://www.linkedin.com/in/wayanberdyanto/">
                                        Follow Me
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="projects" className="px-12 pt-32 space-y-4">
                    <div className='flex justify-between items-center font-normal tracking-widest text-dark dark:text-light my-3 text-xl'>
                        <h3>My Project</h3>
                        <a href="https://github.com/WayanBerdyanto?tab=repositories" className='underline hover:text-primary duration-300'>
                            All Project
                        </a>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-5 text-dark dark:text-light">
                        {projects.map((project, index) => (
                            <div key={index} className="animated-border relative overflow-hidden rounded-lg">
                                <a href={project.link} target='_blank' rel="noopener noreferrer"
                                    className='block px-4 py-6 rounded-md shadow-lg duration-300 hover:scale-105 hover:bg-gray-100 dark:bg-dark dark:bg-opacity-40 dark:hover:bg-opacity-20 bg-white'>
                                    <div className='space-y-2'>
                                        <div className='bg-cover min-w-full'>
                                            <img src={project.image} alt={project.title} className='rounded-lg w-full h-48 object-cover' />
                                        </div>
                                        <h2 className='text-xl font-normal'>{project.title}</h2>
                                        <p className='text-sm'>{project.description}</p>
                                        {project.title === "Eclass React" && (
                                            <a href="https://github.com/WayanBerdyanto/Eclass" className='underline text-primary ml-2'>
                                                Click
                                            </a>
                                        )}
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </section>

                <section id="experience" className="px-12 pt-32 space-y-4">
                    <div className="container">
                        <div className="flex flex-wrap">
                            <div className="w-full px-2">
                                <h1 className="text-center tracking-widest text-dark text-2xl font-light mb-8 dark:text-light uppercase">experience</h1>
                                <div className="flex justify-center" >

                                    <ol class="relative border-s border-gray-200 dark:border-gray-700">
                                        <li class="mb-10 ms-4">
                                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                            <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Apr - Sep 2024</time>
                                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white"> Asisten Dosen Project Read And Read | Dosen Page</h3>
                                            <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400"> Sebagai Asisten Project Read And Read, aplikasi Read And Read adalah aplikasi pembelajaran bahasa inggris bagi mahasiswa Universitas Tidar ( UNTIDAR). Prject ini terdiri dari 3 Mahasiswa dan dibagi tugas masing-masing dalam pengembangan project ini. Saya bertugas mengembangkan halaman Dosen yang memilki beberapa fitur Dosen Update Profile, Change Password Dosen, List Tugas, Detail Tugas, Share Tugas, Input Nilai dan masih banyak fitur yang akan dikembangkan selanjutnya</p>
                                        </li>
                                        <li class="mb-10 ms-4">
                                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                            <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Feb - Jun 2024</time>
                                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white"> Asisten Dosen Pemrograman Berbasis WEB</h3>
                                            <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400"> Sebagai Asisten Dosen Pemrograman Berbasis Web, tanggung jawab saya adalah membantu mahasiswa
                                                mempelajari dasar HTML, CSS, PHP, dan JavaScript dasar. Kemudian untuk mengembangkan aplikasi seperti
                                                halaman admin dan katalog yang terintegrasi dengan database menggunakan Laravel. RDBMS yang
                                                digunakan adalah MySql</p>
                                        </li>
                                        <li class="mb-10 ms-4">
                                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                            <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"> 14 Ags - 31 Des 2023</time>
                                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white"> Studi Independent 2023 | Bangkit Academy 2023 By Google, GoTo,
                                                Traveloka - Android Learning Path</h3>
                                            <p class="text-base font-normal text-gray-500 dark:text-gray-400"> Berkolaborasi dengan teman-teman dalam Mengembangkan Sistem Aplikasi Seluler berbasis EcoScan yang
                                                membantu dalam mengurangi emisi karbon pada makanan. Saya adalah frontend dalam proyek dan menjadi
                                                UI/UX dalam merancang Antarmuka Pengguna. Bahasa pemrograman yang digunakan adalah Kotlin, dalam
                                                proyek saya menggunakan arsitektur MVVM (Model - View -ViewModel) dan mengimplementasikan Retrofit
                                                untuk mengambil API.</p>
                                        </li>
                                        <li class="ms-4">
                                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                            <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"> 11 Apr- 25 Ags 2023</time>
                                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white"> Asisten Dosen proyek Duta Tani Web</h3>
                                            <p class="text-base font-normal text-gray-500 dark:text-gray-400"> mengembangkan aplikasi pemetaan lahan pertanian di Kulon Progo, sebagai asisten saya melanjutkan
                                                website yang telah dikembangkan sebelumnya. Dengan menambahkan pemetaan menggunakan Google
                                                Maps BING. Framework yang digunakan pada project ini adalah Laravel 5 dan bootstrap, kemudian untuk
                                                databasenya menggunakan MySql</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="skills" className="px-12 pt-32 space-y-4">
                    <div className="container">
                        <div className="flex flex-wrap">
                            <div className="w-full py-2">
                                <div className="py-12" id="skills">
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                        <h1 className="text-center tracking-widest text-dark text-2xl font-light mb-8 uppercase dark:text-light">My Skills</h1>
                                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                            {skills.map((category) => (
                                                <div key={category.name} className="border-[1px] border-primary overflow-hidden shadow rounded-lg">
                                                    <div className="p-5">
                                                        <div className="flex items-center">
                                                            {category.icon}
                                                            <h3 className="ml-3 text-lg font-medium text-gray-900 dark:text-light">{category.name}</h3>
                                                        </div>
                                                        <div className="mt-4">
                                                            <ul className="list-disc list-inside">
                                                                {category.items.map((item) => (
                                                                    <li key={item} className="text-gray-400">{item}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer class="px-12 pt-32 space-y-4">
                <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://wayanberdyanto.online/" class="hover:underline">Wayan Berdyanto</a>
                        </span>
                        <div className='flex gap-4 justify-center my-4'>
                            <a href="https://github.com/WayanBerdyanto" className='hover:text-red-600 duration-500'>
                                <i class="bi bi-github"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/wayanberdyanto/" className='hover:text-blue-600 duration-500'>
                                <i class="bi bi-linkedin"></i>
                            </a>
                            <a href="https://www.instagram.com/_alphabetqwerty/" className='hover:text-pink-600 duration-500'>
                                <i class="bi bi-instagram"></i>
                            </a>
                            <a href="https://discord.com/berdyxz/1138317650158686300" className='hover:text-pink-600 duration-500'>
                                <i class="bi bi-discord"></i>
                            </a>
                        </div>
                    </div>
                    <ul class="text-gray-500 dark:text-gray-400 font-medium">
                        <li class="mb-4">
                            <a href="#home" class=" hover:underline">Home</a>
                        </li>
                        <li class="mb-4">
                            <a href="#about" class="hover:underline">About</a>
                        </li>
                        <li class="mb-4">
                            <a href="#projects" class="hover:underline">Projects</a>
                        </li>
                        <li class="mb-4">
                            <a href="#experience" class="hover:underline">Experience</a>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default Home;