import Interior from '../images/Projects/Interior.png'
import LearningDetik from '../images/Projects/LearningDetik.png'
import KmhdUkdw from '../images/Projects/KMHDUKDW.png'
import EclassReact from '../images/Projects/EclassReact.png'

const projects = [
    {
        title: "Interior Catalog",
        description: "Sebuah Aplikasi Sederhana yang berisi Gambar Interior untuk pengembangan belajar Front End",
        image: Interior,
        link: "http://wayanberdyanto.github.io/interior/"
    },
    {
        title: "Learning Detik",
        description: "Aplikasi Learning detik, user dapat melakukan registrasi dan terdapat 2 role yakni user dan admin, admin dapat menambah content List Traning",
        image: LearningDetik,
        link: "https://github.com/WayanBerdyanto/LearningDetik"
    },
    {
        title: "KMHD UKDW",
        description: "Website ini digunakan untuk mempromosikan KMHD UKDW sebagai UKKR, didalam sistem ini terdapat informasi mengenai KMHD UKDW, selain Mahasiswa UKDW dapat mendaftar sebagai Anggota",
        image: KmhdUkdw,
        link: "https://wayanberdyanto.github.io/kmhdukdw2023/"
    },
    {
        title: "Eclass React",
        description: "Aplikasi sederhana CRUD mahasiswa menggunakan React Js dan mengcall sebuah API yang dibuat dengan menggunakan Dotnet Core C#",
        image: EclassReact,
        link: "https://github.com/WayanBerdyanto/EclassReact"
    }
]

export default projects