function About(){
    return(
        <section className="py-8 px-12  pt-48">
            <div className="container">
                <h1 className="text-center text-primary text-4xl font-semibold">About</h1>
            </div>
        </section>
    );
}

export default About;